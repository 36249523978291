import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/layout";

const FAQ = () => {
  return (
    <Layout title={`faq`}>
      <Container>
        <Row>
          <Col className="mt-5">
            <h1>FAQ</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-5">
            <h3 className="mt-4">
              How can I pay my employees through direct deposit?
            </h3>
            You can set-up direct deposit through your bank branch or through a{" "}
            <a title="Payroll Providers" href="/categories/payroll/">
              payroll provider
            </a>
            .
            <h3 className="mt-4">
              What are some secure and accurate methods to keep track of
              employee time clocks?
            </h3>
            There are several methods for employee time and attendance tracking.
            Time clock software and some Payroll services offer options such as:
            <ul>
              <li>
                <p>
                  Online time recording: Employees clock in and out through a
                  web browser
                </p>
              </li>
              <li>
                <p>
                  Barcode time clock: Employees swipe bar-coded badges to record
                  time in and out
                </p>
              </li>{" "}
              <li>
                <p>
                  Biometric time clock: Employees scan fingerprints on clock’s
                  reader to record time in and out. This option improves
                  accuracy by eliminating ‘buddy punching’.
                </p>
              </li>
            </ul>
            <h3 className="mt-4">What are taxable vs non-taxable items?</h3>
            Some examples of taxable items include:
            <ul>
              <li>
                <p>Salaries, wages, bonuses, commissions and honoraria</p>
              </li>
              <li>
                <p>Vacation travel assistance</p>
              </li>
              <li>
                <p>Directors’ and management fees</p>
              </li>
              <li>
                <p>Employer contributions to RRSP’s</p>
              </li>
              <li>
                <p>Retiring allowances or severance pay</p>
              </li>
            </ul>
            Some examples of non-taxable items include:
            <ul>
              <li>
                <p>Income from pensions or superannuation</p>
              </li>
              <li>
                <p>
                  Employee reimbursement of expenses paid on behalf of the
                  employer
                </p>
              </li>
              <li>
                <p>
                  Employer contributions to registered pension plans or funds,
                  group sickness or accident plans and employee benefit plans
                </p>
              </li>
              <li>
                <p>Value of clergy residences</p>
              </li>
              <li>
                <p>
                  Income received by persons who belong to a religious order in
                  which they have taken a vow of perpetual poverty. Employers
                  must deduct NWT payroll tax at source and remit to the GNWT
                  according to a schedule provided at the time of registration.
                </p>
              </li>
            </ul>
            <h3 className="mt-4">What are statutory payroll deductions?</h3>
            Statutory payroll deductions are taxes deducted from employee
            paychecks as required by law. These include:
            <ul>
              <li>
                <p>
                  Federal income tax (based on withholding table in Publication
                  15)
                </p>
              </li>
              <li>
                <p>
                  Social Security tax withholding (6.2% up to the annual
                  maximum)
                </p>
              </li>
              <li>
                <p>Medicare tax withholding (1.45%)</p>
              </li>
              <li>
                <p>State income tax withholding</p>
              </li>
              <li>
                <p>
                  Various local tax withholding (city, county or school district
                  taxes, state disability or unemployment insurance)
                </p>
              </li>
            </ul>
            <h3 className="mt-4">What are voluntary payroll deductions?</h3>
            Voluntary payroll deductions are deductions that pay for various
            benefits in which the employee has chosen to articipate. The funds
            are held from the paycheque only if the employee has agreed to the
            deduction. Some examples of voluntary payroll deductions include:
            <ul>
              <li>
                <p>
                  Meals, uniforms, union-dues and any other job-related expenses
                </p>
              </li>
              <li>
                <p>Retirement plan contributions</p>
              </li>
              <li>
                <p>Health insurance premiums</p>
              </li>
              <li>
                <p>Life insurance premiums</p>
              </li>
              <li>
                <p>Employee Stock Purchase Plans (ESSP and ESOP)</p>
              </li>
            </ul>
            <p>
              Pre or Post-tax dollars can be used to pay voluntary payroll
              deductions depending on the type of deduction. Professional
              payroll services can be used to accurately keep track of all
              deductions.
            </p>
            <h3 className="mt-4">
              How do I know if a worker should be classified as a W2 employee or
              a 1099 contractor?
            </h3>
            An individual is a contract worker if the employer has control only
            over the result of the work being done, not what will be done and
            how the work will be completed. Independent contractors are
            considered to be self-employed and are responsible for adhering to
            Self-Employment Tax obligations. An employee is someone who
            completes work in a manner set out and supervised by the employer,
            even if they are given freedom of action. The important point is
            that the employer has the legal right to control the details of how
            the work is being performed. Employee paychecks are subject to
            social security, medicare and income tax withholding.
            <h3 className="mt-4">
              When am I required to pay my employees overtime?
            </h3>
            Employers are required to compensate employees for any hours worked
            in excess of 40 hours per week. The standard overtime rate is 1.5
            times their regular hourly wage. Overtime rates are not required by
            the FLSA for hours worked on weekends, nights or holidays unless
            overtime hours are worked on those days or otherwise required by
            state law. Employers should refer to the FLSA’s regulations for
            proper classification of exempt vs. nonexempt employees.
            <h3 className="mt-4">
              What are my responsibilities as an employer in terms of wage
              garnishments and child-support?
            </h3>
            An employer is entitled to withhold 50-65% of an employee's
            disposable income toward wage garnishment and child support
            payments. When an employer receives the Order/Notice-Income
            Withholding for support, they should do as follows:
            <ul>
              <li>
                <p>Date stamp the documents</p>
              </li>
              <li>
                <p>
                  Determine authenticity of the document. It should include the
                  amount of child support, terms for past-due, medical support
                  terms (if ordered) and where to remit the payments.
                </p>
              </li>
              <li>
                <p>
                  If it has been issued by another state, give a copy of the
                  Order/Notice to the employee
                </p>
              </li>
              <li>
                <p>
                  Follow the terms of the order and assess the fees as
                  appropriate
                </p>
              </li>
            </ul>
            <p>
              Depending on state law, employers may be able to retain a fee of
              up to $5 per month for withholding of income. Fee must be taken
              from employee wages and not from deductions withheld.
            </p>
            <h3 className="mt-4">
              What are the tax implications of giving employee gifts and awards?
            </h3>
            The taxability of employee gifts usually depends on the value and
            type or gift or award. Any sort of gift that holds monetary value
            such as bonuses, gift certificates or awards that involve goods and
            services are generally considered taxable income. ‘Tangible personal
            property’ such as length of service or safety awards are not
            generally considered taxable income, provided that the strict
            tax-free treatment rules are followed. The term ‘tangible personal
            property’ does not encompass any certificate redeemable for
            merchandise, including points and cards with points value. There is
            an exception to that rule, provided the merchandise is given as an
            employee achievement award and meets the standards set by the
            IRS.Gifts and awards holding minimal value may fall under the IRS’s
            ‘de minimis’ rule and are not taxable. That rule states that if an
            employee receives a gift that holds a value so minimal that it is
            unreasonable for the employer to account for it, the value is not
            considered to be taxable income. This includes smaller scale items
            such as a holiday turkey and does not include any monetary items
            such as gift cards/certificates. Most tax advisers say that anything
            of value under $75 to any given employee in a year is considered
            minimal, however you should consult with your adviser for the ‘de
            minimis’ amount to use in your situation.
            <h4 className="mt-4">
              How can I pay my employees through direct deposit?
            </h4>
            You can set-up direct deposit through your bank branch or through a{" "}
            <a title="Payroll Providers" href="/categories/payroll/">
              payroll provider
            </a>
            .
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default FAQ;
